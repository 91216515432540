import BgSvg from "../images/bg.svg";
import React, { useCallback, useEffect, useRef, useState } from "react";

import logo from "../images/logo.png";
import gp from "../images/gp.svg";
import as from "../images/as.svg";

import 'animate.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../styles/Home.css";

// import required modules
import { Mousewheel, Pagination } from "swiper";
import ScreenSwiper from "../components/ScreenSwiper";
import Header from "../components/Header";

const IndexPage = () => {
  const [number, setNumber] = useState("0");
  
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const scrollHandler = (index) => {
    if (index === 0) setNumber("0");
    if (index === 1) setNumber("180");
    if (index === 2) setNumber("360");
  };
  const updateIndex = useCallback(() => {
    setCurrentSlide(swiperRef.current.swiper.realIndex);
    scrollHandler(swiperRef.current.swiper.realIndex);
  }, []);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex]);
  return (
    <>
      <div className="App-header">
        <Header currentSlide={currentSlide}/>
        <div
          className={`mkdf-vertical-showcase ${
            currentSlide === 3 ? "mkdf-vs-last-slide" : ""
          }`}
        >
          <div
            className="mkdf-vs-holder"
            style={{ transform: `rotate(${number}deg)` }}
          >
            <img src={BgSvg} alt="background" />
          </div>
          <div className="logo">
            <div className="flex row">
              <img src={as} alt="apple store" className="animate__animated animate__fadeInLeft"/>
              <img src={logo} alt="logo" className="animate__animated animate__rubberBand"/>
              <img src={gp} alt="google play" className="animate__animated animate__fadeInRight" />
            </div>
          </div>
          <div class="mkdf-vs-frame-holder">
            <div class="mkdf-vs-frame-mobile-holder">
              <img
                src="https://foton.qodeinteractive.com/wp-content/plugins/foton-core/assets/img/mobile-frame-shadow.png"
                alt="Mobile frame"
              />
              <ScreenSwiper currentSlide={currentSlide} />
            </div>
          </div>

          <Swiper
            direction={"vertical"}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={{
              thresholdTime: 1200,
            }
            }
            pagination={{
              clickable: true,
            }}
            modules={[Mousewheel, Pagination]}
            className="swiper-container"
            ref={swiperRef}
          >
            <SwiperSlide>
              <div class="mkdf-vs-item" style={{ height: "1111px" }}>
                <img
                  width="322"
                  height="691"
                  src="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-1.png"
                  class="attachment-full size-full"
                  alt="screen-1"
                  loading="lazy"
                  srcset="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-1.png 322w, https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-1-140x300.png 140w"
                  sizes="(max-width: 322px) 100vw, 322px"
                />{" "}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="mkdf-vs-item">
                <img
                  width="322"
                  height="691"
                  src="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2.png"
                  class="attachment-full size-full"
                  alt="screen-2"
                  loading="lazy"
                  srcset="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2.png 322w, https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2-140x300.png 140w"
                  sizes="(max-width: 322px) 100vw, 322px"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="mkdf-vs-item">
                <img
                  width="322"
                  height="691"
                  src="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2.png"
                  class="attachment-full size-full"
                  alt="screen-2"
                  loading="lazy"
                  srcset="https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2.png 322w, https://foton.qodeinteractive.com/wp-content/uploads/2019/12/screen-2-140x300.png 140w"
                  sizes="(max-width: 322px) 100vw, 322px"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Speed Reader | Home</title>;
