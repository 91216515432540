import ss1 from "../images/ss.png";
import ss2 from "../images/ss2.png";

import React, { useEffect, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";

import "../styles/Home.css";

// import required modules
import { EffectCreative } from "swiper";
const ScreenSwiper = ({ currentSlide }) => {
    const swiperRef = useRef(null);
    useEffect(() => {
        swiperRef.current?.swiper.slideTo(currentSlide);
    }, [currentSlide])
    
  return (
    <>
      <div class="mkdf-vs-inner-frame">
        <Swiper
          grabCursor={true}
          effect={"creative"}
          speed={1000}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative]}
          className="mySwiper swiper-ss"
          ref={swiperRef}
        >
          <SwiperSlide>
            <div>
              <img src={ss1} alt="screen-1" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={ss2} alt="screen-2" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={ss2} alt="screen-3" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default ScreenSwiper;
